@font-face{
  font-family: 'Roboto';
  font-display: swap;
  src: url('./fonts/roboto/Roboto-Regular.ttf') format('truetype');
}

.wf-loading body, .wf-inactive body {
  visibility: hidden;
}
.wf-active body {
  visibility: visible;
  font-family: Roboto;
}

